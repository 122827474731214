// Dashboard.js
import React, { useEffect, useRef, useState } from "react";
import MaxWidthWrapper from "../components/max-width-warpper";
import { cn } from "../utils/cn";
import { GoGrabber } from "react-icons/go";
import { constants } from "../utils/constants";
import { defaultAxios } from "../service/axios";
import { requests } from "../service/request";
import ModalBox from "../components/modal-box";
import ContextMenu from "../components/context-menu";
import Forbidden from "../components/forbidden";
import EmptyGroup from "../components/empty-group";
import SkeltonLoading from "../components/skelton-loding";
import DropArea from "../components/drop-area";
import Error from "../components/error";

const Dashboard = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    x: 0,
    y: 0,
    options: [],
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isForbidden, setIsForbidden] = useState(false);
  const [activeBookmark, setActiveBookmark] = useState(null);
  const [fetchingError, setFetchingError] = useState(false);

  useEffect(() => {
    handleFetchBookmarks(true);
  }, []);

  const handleFetchBookmarks = async (loading = false) => {
    setFetchingError(false);
    try {
      loading && setIsLoading(true);
      let { data } = await defaultAxios.get(requests.bookmarks);
      if (data?.status === constants.SUCCESS) {
        setBookmarks(data?.bookmarks || []);
      } else if (data.status === constants.FORBIDDEN) {
        setIsForbidden(true);
      } else {
        setFetchingError(true);
      }
    } catch (error) {
      setFetchingError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActions = (
    action,
    groupId = "",
    groupName = "",
    bookmark = null
  ) => {
    setModalProps({
      action,
      groupId,
      groupName,
      bookmark,
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalProps({});
    setError(null);
  };

  const handleCreateAndEdit = async (action, name, link, group_id, id) => {
    try {
      setIsModalLoading(true);
      if (action === constants.ACTION_ADD_ITEM) {
        const bookmark = { name, link, group_id };
        let { data } = await defaultAxios.post(requests.bookmarks, bookmark);
        if (data?.status === constants.SUCCESS) {
          setShowModal(false);
          handleFetchBookmarks();
        } else {
          setError(constants.COMMON_ERROR);
        }
      } else {
        const bookmark = { name, link, group_id, id };
        let { data } = await defaultAxios.put(requests.bookmarks, bookmark);
        if (data?.status === constants.SUCCESS) {
          setShowModal(false);
          handleFetchBookmarks();
        } else {
          setError(constants.COMMON_ERROR);
        }
      }
    } catch (error) {
      setError(constants.COMMON_ERROR);
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleDelete = async (action, groupId, id) => {
    try {
      setIsModalLoading(true);
      if (action === constants.ACTION_DELETE_ITEM) {
        const request = `${requests.bookmarks}?g_id=${groupId}&id=${id}`;
        let { data } = await defaultAxios.delete(request);
        if (data?.status === constants.SUCCESS) {
          setShowModal(false);
          handleFetchBookmarks();
        } else {
          setError(constants.COMMON_ERROR);
        }
      } else {
        setError(constants.COMMON_ERROR);
      }
    } catch (error) {
      setError(constants.COMMON_ERROR);
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleRightClick = (event, group_id, title, bookmark) => {
    event.preventDefault();
    setContextMenu({
      isVisible: true,
      x: event.clientX + 5,
      y: event.clientY + 5 + scrollPosition,
      options: [
        {
          label: "Open",
          onClick: () => window.open(bookmark?.link, "_blank"),
        },
        {
          label: "Copy",
          onClick: () => {
            bookmark?.link
              ? navigator.clipboard.writeText(bookmark?.link)
              : navigator.clipboard.writeText(bookmark?.name);
          },
        },
        {
          label: "Edit",
          onClick: () =>
            handleActions(
              constants.ACTION_EDIT_ITEM,
              group_id,
              title,
              bookmark
            ),
        },

        {
          label: "Delete",
          onClick: () =>
            handleActions(
              constants.ACTION_DELETE_ITEM,
              group_id,
              title,
              bookmark
            ),
        },
        // Add more options as needed
      ],
    });
  };

  // for group name
  const handleGroupRightClick = (event, group_id, title) => {
    event.preventDefault();
    setContextMenu({
      isVisible: true,
      x: event.clientX + 5,
      y: event.clientY + 5 + scrollPosition,
      options: [
        {
          label: "Edit",
          onClick: () =>
            handleActions(constants.ACTION_EDIT_GROUP_NAME, group_id, title),
        },

        {
          label: "New Group",
          onClick: () => handleActions(constants.ACTION_ADD_GROUP),
        },
        {
          label: "New Bookmark",
          onClick: () =>
            handleActions(constants.ACTION_ADD_ITEM, group_id, title),
        },
        // Add more options as needed
      ],
    });
  };

  const handleCreateAndEditGroupName = async (action, group_id, name) => {
    try {
      setIsModalLoading(true);
      if (action === constants.ACTION_EDIT_GROUP_NAME) {
        const group = { name, group_id };
        let { data } = await defaultAxios.put(requests.groups, group);
        if (data?.status === constants.SUCCESS) {
          setShowModal(false);
          handleFetchBookmarks();
        } else {
          setError(constants.COMMON_ERROR);
        }
      } else if (action === constants.ACTION_ADD_GROUP) {
        const group = { name };
        let { data } = await defaultAxios.post(requests.groups, group);
        if (data?.status === constants.SUCCESS) {
          setShowModal(false);
          handleFetchBookmarks();
        } else {
          setError(constants.COMMON_ERROR);
        }
      }
    } catch (error) {
      setError(constants.COMMON_ERROR);
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleClickOutside = () => {
    setContextMenu({ ...contextMenu, isVisible: false });
  };

  const handleScrollOption = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    if (contextMenu.isVisible) {
      window.addEventListener("scroll", handleClickOutside);
    } else {
      window.removeEventListener("scroll", handleClickOutside);
    }
    window.addEventListener("scroll", handleScrollOption);
    return () => {
      window.removeEventListener("scroll", handleScrollOption);
    };
  }, []);

  useEffect(() => {
    if (contextMenu.isVisible) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu.isVisible]);

  const onDrop = (from, to, id, position) => {
    console.log(from, to, id, position);
    moveBookmark(from, to, id, position);
  };

  const moveBookmark = (fromGroupId, toGroupId, bookmarkId, position) => {
    const data = bookmarks;
    // Find the fromGroup and toGroup
    const fromGroup = data.find((group) => group.id === fromGroupId);
    const toGroup = data.find((group) => group.id === toGroupId);

    // Find the bookmark in the fromGroup
    const bookmarkIndex = fromGroup.bookmarks.findIndex(
      (bm) => bm.id === bookmarkId
    );

    // Remove the bookmark from the fromGroup
    const [bookmark] = fromGroup.bookmarks.splice(bookmarkIndex, 1);

    // Insert the bookmark into the toGroup at the specified position
    toGroup.bookmarks.splice(position, 0, bookmark);

    // Return the modified data as a JSON string
    setBookmarks(data);
    handleMoveBookmarkInDB(fromGroupId, toGroupId, bookmarkId, position);
  };

  const handleMoveBookmarkInDB = async (
    fromGroupId,
    toGroupId,
    bookmarkId,
    position
  ) => {
    try {
      const request = `${requests.bookmarks}?fromGroupId=${fromGroupId}&toGroupId=${toGroupId}&bookmarkId=${bookmarkId}&position=${position}`;
      let { data } = await defaultAxios.patch(request);
      if (data.status === constants.SUCCESS) {
        handleFetchBookmarks();
      } else {
        setFetchingError(true);
      }
    } catch (error) {
      setFetchingError(true);
    }
  };

  if (isForbidden && !isLoading) return <Forbidden />;

  if (!bookmarks.length > 0 && !isLoading && !isForbidden && !fetchingError)
    return <EmptyGroup handleActions={handleActions} />;

  if (isLoading) return <SkeltonLoading />;

  if (!isLoading && fetchingError)
    return <Error handleFetch={handleFetchBookmarks} />;

  // console.log(JSON.stringify(bookmarks));

  return (
    <MaxWidthWrapper>
      <>
        <h2 className="font-bold text-[50px] w-full text-center my-10 text-zinc-500/20 select-none ">
          Bookmarks
        </h2>
        <div className="w-full h-full  grid lg:grid-cols-3 sm:grid-cols-2 gap-y-10 gap-x-3">
          {bookmarks?.map(({ title, id: group_id, bookmarks }, i) => (
            <div key={group_id} className="w-full">
              <div>
                <div
                  onContextMenu={(event) => {
                    handleGroupRightClick(event, group_id, title);
                  }}
                  onClick={() => {
                    activeIndex === -1 || activeIndex !== i
                      ? setActiveIndex(i)
                      : setActiveIndex(-1);
                  }}
                  className="w-full flex cursor-pointer justify-between bg-zinc-900/70 border border-zinc-800 border-dashed py-3 px-10 border-r border-l"
                >
                  <h4
                    className={cn(
                      "text-center capitalize text-base text-zinc-500 font-medium truncate hover:text-clip"
                    )}
                  >
                    {title}
                  </h4>
                </div>
                <DropArea
                  onDrop={() => {
                    onDrop(
                      activeBookmark.groupId,
                      group_id,
                      activeBookmark.id,
                      0
                    );
                  }}
                />
                {bookmarks.map((bookmark, i) => (
                  <>
                    <div
                      onClick={() => {
                        window.open(bookmark?.link, "_blank");
                      }}
                      onContextMenu={(event) =>
                        handleRightClick(event, group_id, title, bookmark)
                      }
                      key={bookmark.id}
                      className="font-mono capitalize w-full odd:bg-zinc-800/10 cursor-pointer"
                    >
                      <ul>
                        <li
                          draggable
                          onDragStart={() => {
                            setActiveBookmark({
                              groupId: group_id,
                              id: bookmark.id,
                            });
                          }}
                          onDragEnd={() => {
                            setActiveBookmark(null);
                          }}
                          className={cn(
                            "border flex items-center font-semibold w-full text-zinc-400 text-[15px] border-dashed border-zinc-800 p-3",
                            activeBookmark?.id === bookmark.id && "border-white"
                          )}
                        >
                          <GoGrabber className="text-zinc-400 mr-3 w-6 h-6 bg-zinc-900 rounded-md cursor-grab" />
                          {bookmark?.name}
                        </li>
                      </ul>
                      <DropArea
                        onDrop={() => {
                          onDrop(
                            activeBookmark.groupId,
                            group_id,
                            activeBookmark.id,
                            i + 1
                          );
                        }}
                      />
                    </div>
                  </>
                ))}
                {!bookmarks.length > 0 && (
                  <div
                    onClick={() => {
                      handleActions(constants.ACTION_ADD_ITEM, group_id, title);
                    }}
                    className="flex px-2 py-4 border border-dashed border-t-0 border-zinc-800 cursor-pointer"
                  >
                    <p className="text-sm font-bold font-mono">
                      Empty. Click hear to Create Bookmark
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </>

      <ModalBox
        showModal={showModal}
        props={modalProps}
        handleClose={handleCloseModal}
        handleCreateAndEdit={handleCreateAndEdit}
        handleCreateAndEditGroupName={handleCreateAndEditGroupName}
        handleDelete={handleDelete}
        setError={setError}
        error={error}
        isModalLoading={isModalLoading}
      />
      <ContextMenu {...contextMenu} />
    </MaxWidthWrapper>
  );
};

export default Dashboard;
