import React from "react";
import { constants } from "../utils/constants";
import { cn } from "../utils/cn";

function Error({handleFetch}) {
  return (
    <div className="flex flex-col absolute h-full w-full justify-center items-center px-10">
      <p className="font-mono text-[15px] text-zinc-400 mb-5">
          {constants.FETCHING_ERROR}
        </p>
        <button
          onClick={() => {
          handleFetch(true)
          }}
          className={cn(
            "bg-transparent hover:bg-zinc-900 duration-200 ease-linear  h-[48px] flex items-center justify-center my-2 border border-zinc-800 text-white py-4 px-10 rounded-md text-base font-mono font-semibold"
          )}
        >
        Refresh
        </button>
    </div>
  );
}

export default Error;
