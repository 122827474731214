import React from "react";
import MaxWidthWrapper from "./max-width-warpper";

const SkeltonLoading = () => {
  const skelton = Array.from(Array(5).keys());
  return (
    <MaxWidthWrapper>
      <>
        <div className="mx-auto w-full max-w-96 h-11  bg-zinc-800/70 my-14 rounded-md animate-pulse"></div>
        <div className="w-full h-full  grid lg:grid-cols-3 sm:grid-cols-2 gap-y-10 gap-x-3">
          {skelton?.map((i) => (
            <div key={i} className="w-full">
              <div>
                <div className="w-full flex justify-between bg-zinc-900/70 border border-zinc-800 border-dashed py-3 px-10 border-r border-l">
                  <div className="w-full max-w-32 h-6  bg-zinc-800/70 rounded-md animate-pulse" />
                </div>
                {skelton.map((i) => (
                  <div key={i} className=" odd:bg-zinc-800/10">
                    <ul>
                      <div className="border-b border-l border-r border-dashed border-zinc-800  p-3 ">
                        <div className="w-full max-w-80 h-4 text-center bg-zinc-800/70  rounded-md animate-pulse" />
                      </div>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    </MaxWidthWrapper>
  );
};

export default SkeltonLoading;
