import React, { useState } from "react";
import { cn } from "../utils/cn";

const DropArea = ({ onDrop }) => {
  const [showDrop, setShowDrop] = useState(false);

  return (
    <section
      onDragEnter={(e) => {
        e.preventDefault();
        setShowDrop(true);
      }}
      onDrop={() => {
        onDrop();
        setShowDrop(false);
      }}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={(e) => {
        e.preventDefault();
        setShowDrop(false);
      }}
      className={cn(
        "w-full h-12 bg-zinc-900 opacity-100 transition-all duration-200 ease-in-out",
        !showDrop && "h-1 w-full"
      )}
    >
    </section>
  );
};

export default DropArea;
