import React from "react";
import Dashboard from "./pages/dashboard";
import Popup from "./components/Popup";

const App = () => {
  return (
    <div id="bookmarkMenu" className="relative overflow-x-hidden min-w-96 flex flex-col min-h-screen pb-20 bg-zinc-950 text-zinc-100 selection:dark:bg-slate-100 selection:dark:text-zinc-950 selection:bg-zinc-950 selection:text-zinc-100 app_class">
      <Dashboard />
      {/* <Popup/> */}
    </div>
  );
};

export default App;
