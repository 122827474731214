import React from 'react'
import { cn } from '../utils/cn';
import { constants } from '../utils/constants';

const EmptyGroup = ({handleActions}) => {
  return (
    <div className="flex flex-col absolute h-full w-full justify-center items-center px-10">
        <p className="font-mono text-[15px] text-zinc-400 mb-5">
          {constants.EMPTY_BOOKMARKS}
        </p>
        <button
          onClick={() => {
            handleActions(constants.ACTION_ADD_GROUP);
          }}
          className={cn(
            "bg-transparent hover:bg-zinc-900 duration-200 ease-linear  h-[48px] flex items-center justify-center my-2 border border-zinc-800 text-white py-4 px-10 rounded-md text-base font-mono font-semibold"
          )}
        >
          Create New Group
        </button>
      </div>
  )
}

export default EmptyGroup