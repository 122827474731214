import React, { useState } from "react";
import { constants } from "../utils/constants";
import { MdErrorOutline } from "react-icons/md";
import { cn } from "../utils/cn";

const primaryButton =
  "bg-zinc-700 hover:bg-zinc-800 transition-all ease-in-out duration-200 max-[350px]:min-w-full max-w-[150px] h-[48px] w-[120px] flex items-center justify-center my-2 border border-transparent text-white rounded-md text-base font-mono font-semibold";
const secondaryButton =
  "bg-transparent hover:bg-zinc-800 transition-all ease-in-out duration-200 max-[350px]:min-w-full max-w-[150px] h-[48px] w-[120px] flex items-center justify-center my-2 border border-zinc-800 text-white py-4 px-10 rounded-md text-base font-mono font-semibold";

const ModalBox = (props) => {
  const {
    showModal,
    props: propData,
    handleClose,
    handleCreateAndEdit,
    handleDelete,
    setError,
    error,
    isModalLoading,
    handleCreateAndEditGroupName,
  } = props;
  const { action, groupId, groupName, bookmark } = propData;

  if (showModal)
    return (
      <div className="w-full h-screen  bg-zinc-950/20 mx-auto p-5 absolute flex justify-center items-center top-0 left-0 right-0 bottom-0 z-[9999] ">
        <div className="w-full flex justify-center items-center"> 
          <div
            className={cn(
              "w-full max-w-[650px] bg-zinc-900 rounded-md mx-auto pt-10",
              action === constants.ACTION_DELETE_ITEM && "max-w-[550px]",
              (action === constants.ACTION_ADD_ITEM ||
                action === constants.ACTION_EDIT_ITEM) &&
                "max-w-[750px]"
            )}
          >
            <h1 className="text-[22px] md:text-[24px] text-light-white font-bold tracking-wide capitalize pb-3 text-center px-2 ">
              {constants.MODAL_TITLE[action]}
            </h1>
            {/* for add bookmark and edit bookmark */}
            {(action === constants.ACTION_ADD_ITEM ||
              action === constants.ACTION_EDIT_ITEM) && (
              <CreateAndEditBookmark
                groupName={groupName}
                name={bookmark?.name}
                link={bookmark?.link}
                groupId={groupId}
                id={bookmark?.id}
                handleClose={handleClose}
                handleCreateAndEdit={handleCreateAndEdit}
                action={action}
                setError={setError}
                error={error}
                isModalLoading={isModalLoading}
              />
            )}
            {/* delete bookmark */}
            {action === constants.ACTION_DELETE_ITEM && (
              <DeleteConfirmation
                name={bookmark?.name}
                link={bookmark?.link}
                groupId={groupId}
                id={bookmark?.id}
                handleClose={handleClose}
                handleDelete={handleDelete}
                action={action}
                setError={setError}
                error={error}
                isModalLoading={isModalLoading}
              />
            )}

            {(action === constants.ACTION_EDIT_GROUP_NAME ||
              action === constants.ACTION_ADD_GROUP) && (
              <CreateAndEditGroupName
                title={groupName || ""}
                groupId={groupId || ""}
                handleClose={handleClose}
                handleCreateAndEditGroupName={handleCreateAndEditGroupName}
                action={action}
                setError={setError}
                error={error}
                isModalLoading={isModalLoading}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export default ModalBox;

const DeleteConfirmation = ({
  groupId,
  name = "",
  id = "",
  handleClose,
  handleDelete,
  action,
  setError,
  error,
  isModalLoading,
}) => {
  const [bookmarkName, setBookmarkName] = useState("");

  const handleChange = (e) => {
    error !== null && setError(null);
    setBookmarkName(e.target.value);
  };

  const handleSubmit = () => {
    setError(null);
    if (bookmarkName.trim() !== "" && bookmarkName.trim() === name) {
      handleDelete(action, groupId, id);
    } else {
      setError(constants.ALL_FIELDS_REQUIRED);
    }
  };

  return (
    <div className="w-full  my-10">
      {error !== null && (
        <div className="flex justify-center items-center flex-col px-2 ">
          <div className="bg-red-600/30 w-full max-w-[420px] flex  px-2 py-4 rounded-md mb-4 items-center">
            <MdErrorOutline className="text-white w-5 h-5 mr-3" />
            <p className="text-sm p-0 max-w-[90%]">{error}</p>
          </div>
        </div>
      )}
      {/* confirm text */}
      <div className="flex justify-center items-center flex-col px-2 ">
        <div className=" w-full flex max-w-[420px] p-1 rounded-md mb-0 items-center">
          <p className="text-sm leading-6 text-zinc-400">
            {constants.DELETE_CONFIRM}{" "}
            <span className="font-bold text-zinc-50 font-mono">{name}</span>
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col px-2">
        {/* group name */}
        <div className="w-full max-w-[420px] ">
          <input
            type="text"
            name="name"
            value={bookmarkName}
            placeholder={name}
            onChange={handleChange}
            className="w-full h-[50px] rounded-md px-2 text-base font-medium font-mono tracking-wide bg-transparent border border-dashed border-zinc-700 focus:border-zinc-400 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex max-[350px]:items-center justify-between max-[350px]:flex-col-reverse max-w-[420px] mx-auto flex-wrap mt-5 px-2">
        <button
          onClick={handleClose}
          disabled={isModalLoading}
          className={cn(
           secondaryButton,
            isModalLoading && "cursor-not-allowed"
          )}
        >
          Close
        </button>
        <button
          onClick={
            !isModalLoading || name === bookmarkName ? handleSubmit : () => {}
          }
          disabled={isModalLoading || name !== bookmarkName}
          className={cn(
            "bg-red-600 hover:bg-red-700 transition-all ease-in-out duration-200 max-[350px]:min-w-full max-w-[150px] h-[48px] w-[120px] flex items-center justify-center my-2 border border-transparent text-white rounded-md text-base font-mono font-semibold",
            (isModalLoading || name !== bookmarkName) &&
              "cursor-not-allowed opacity-40 hover:bg-red-600"
          )}
        >
          {!isModalLoading ? (
            "Delete"
          ) : (
            <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-white/50" />
          )}
        </button>
      </div>
    </div>
  );
};

const CreateAndEditBookmark = ({
  groupName,
  groupId,
  name = "",
  link = "",
  id = "",
  handleClose,
  handleCreateAndEdit,
  action,
  setError,
  error,
  isModalLoading,
}) => {
  const [bookmark, setBookmark] = useState({
    name: name || "",
    link: link || "",
  });

  const handleChange = (e) => {
    error !== null && setError(null);
    setBookmark((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (bookmark.name.trim() !== "" && bookmark.link.trim() !== "") {
      handleCreateAndEdit(action, bookmark.name, bookmark.link, groupId, id);
    } else {
      setError(constants.ALL_FIELDS_REQUIRED);
    }
  };

  return (
    <div className="w-full  my-10">
      {error !== null && (
        <div className="flex justify-center space-y-5 md:space-y-0 md:space-x-5 md:items-start items-center flex-col px-2 ">
          <div className="bg-red-600/30 w-full flex max-w-[420px] md:max-w-full px-2 py-4 rounded-md mb-4 items-center">
            <MdErrorOutline className="text-white w-5 h-5 mr-3" />
            <p className="text-sm p-0 max-w-[90%]">{error}</p>
          </div>
        </div>
      )}
      <div className="flex justify-center space-y-5 md:space-y-0 md:space-x-5 md:items-start items-center flex-col md:flex-row px-2">
        {/* group name */}
        <div className="w-full max-w-[420px] md:max-w-[200px] ">
          <input
            type="text"
            name="groupName"
            value={groupName || ""}
            disabled
            placeholder="Group Name"
            className="w-full h-[50px] text-zinc-600 select-none cursor-not-allowed rounded-md px-2 text-base font-medium font-mono tracking-wide bg-transparent border border-dashed border-zinc-800 focus:border-zinc-400 focus:outline-none"
          />
        </div>
        {/* name */}
        <div className="w-full max-w-[420px] md:max-w-[280px] ">
          <input
            type="text"
            name="name"
            value={bookmark?.name}
            onChange={handleChange}
            placeholder="Bookmark Name"
            className="w-full h-[50px] rounded-md px-2 text-base font-medium font-mono tracking-wide bg-transparent border border-dashed border-zinc-700 focus:border-zinc-400 focus:outline-none"
          />
        </div>
        {/* link */}
        <div className="w-full max-w-[420px] ">
          <input
            type="url"
            name="link"
            value={bookmark?.link}
            onChange={handleChange}
            placeholder="Link"
            className="w-full h-[50px] rounded-md px-2 text-base font-medium font-mono tracking-wide bg-transparent border border-dashed border-zinc-700 focus:border-zinc-400 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex max-[350px]:items-center justify-between max-[350px]:flex-col-reverse max-w-[420px] mx-auto flex-wrap mt-5 px-2">
        <button
          onClick={handleClose}
          disabled={isModalLoading}
          className={cn(
            secondaryButton,
            isModalLoading && "cursor-not-allowed"
          )}
        >
          Close
        </button>
        <button
          onClick={!isModalLoading ? handleSubmit : () => {}}
          disabled={isModalLoading}
          className={cn(primaryButton, isModalLoading && "cursor-not-allowed")}
        >
          {!isModalLoading ? (
            action === constants.ACTION_EDIT_ITEM ? (
              "Update"
            ) : (
              "Create"
            )
          ) : (
            <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-white/50" />
          )}
        </button>
      </div>
    </div>
  );
};

const CreateAndEditGroupName = ({
  groupId,
  title = "",
  handleClose,
  handleCreateAndEditGroupName,
  action,
  setError,
  error,
  isModalLoading,
}) => {
  const [groupName, setGroupName] = useState(title);

  const handleChange = (e) => {
    error !== null && setError(null);
    setGroupName(e.target.value);
  };

  const handleSubmit = () => {
    setError(null);
    if (groupName.trim() !== "") {
      if (groupName.trim() === title) {
        handleClose();
      } else {
        handleCreateAndEditGroupName(action, groupId, groupName);
      }
    } else {
      setError(constants.ALL_FIELDS_REQUIRED);
    }
  };

  return (
    <div className="w-full  my-10">
      {error !== null && (
        <div className="flex justify-center items-center flex-col px-2 ">
          <div className="bg-red-600/30 w-full max-w-[420px] flex  px-2 py-4 rounded-md mb-4 items-center">
            <MdErrorOutline className="text-white w-5 h-5 mr-3" />
            <p className="text-sm p-0 max-w-[90%]">{error}</p>
          </div>
        </div>
      )}

      <div className="flex justify-center items-center flex-col px-2">
        {/* group name */}
        <div className="w-full max-w-[420px] ">
          <input
            type="text"
            name="name"
            value={groupName}
            placeholder="Group Name"
            onChange={handleChange}
            className="w-full h-[50px] rounded-md px-2 text-base font-medium font-mono tracking-wide bg-transparent border border-dashed border-zinc-700 focus:border-zinc-400 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex max-[350px]:items-center justify-between max-[350px]:flex-col-reverse max-w-[420px] mx-auto flex-wrap mt-5 px-2">
        <button
          onClick={handleClose}
          disabled={isModalLoading}
          className={cn(
            secondaryButton,
            isModalLoading && "cursor-not-allowed"
          )}
        >
          Close
        </button>
        <button
          onClick={!isModalLoading ? handleSubmit : () => {}}
          disabled={isModalLoading}
          className={cn(
            primaryButton,
            isModalLoading && "cursor-not-allowed opacity-40"
          )}
        >
          {!isModalLoading ? (
            action === constants.ACTION_EDIT_GROUP_NAME ? (
              "Update"
            ) : (
              "Create"
            )
          ) : (
            <div className="w-8 h-8 border-4 border-dashed rounded-full animate-spin border-white/50" />
          )}
        </button>
      </div>
    </div>
  );
};
