import React from "react";

const Forbidden = () => {
  return (
    <div className="flex absolute h-full w-full justify-center items-center px-10">
      <p
        className="font-mono text-lg font-semibold tracking-wide text-zinc-700
    "
      >
        403 | FORBIDDEN
      </p>
    </div>
  );
};

export default Forbidden;
