export const ACTION_ADD_ITEM = "add_item";
export const ACTION_ADD_GROUP = "add_group";
export const ACTION_EDIT_ITEM = "edit_item";
export const ACTION_DELETE_ITEM = "delete_item";
export const ACTION_EDIT_GROUP_NAME = "edit_group";
export const ACTION_DELETE_GROUP = "delete_group";

export const constants = {
  ACTION_ADD_ITEM,
  ACTION_EDIT_ITEM,
  ACTION_ADD_GROUP,
  ACTION_DELETE_ITEM,
  ACTION_EDIT_GROUP_NAME,
  ACTION_DELETE_GROUP,
  MODAL_TITLE: {
    [ACTION_ADD_ITEM]: "Create a New Bookmark",
    [ACTION_ADD_GROUP]: "Create a New Group",
    [ACTION_EDIT_ITEM]: "Edit Bookmark",
    [ACTION_DELETE_ITEM]: "Delete Bookmark",
    [ACTION_EDIT_GROUP_NAME]: "Edit Group Name",
  },
  COMMON_ERROR: "Oops! Something went wrong. Please try again later.",
  ALL_FIELDS_REQUIRED:
    "All fields are required. Please review and complete all fields before submitting.",
  DELETE_CONFIRM:
    "Confirm you want to delete this bookmark by typing its name: ",
  EMPTY_BOOKMARKS:
    "You don't have any bookmarks yet. Please create a Group and add Bookmarks to it.",
  FETCHING_ERROR:
    "Whoops, something went wrong. Please refresh the page or try again later.",
  // status
  SUCCESS: "200",
  FORBIDDEN: "403",
};
