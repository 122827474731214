// ContextMenu.js
import React from "react";

const ContextMenu = ({ x, y, isVisible, options }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <ul
      className="absolute bg-zinc-950 shadow-md shadow-black rounded-lg border border-dashed border-zinc-700"
      style={{ top: y + "px", left: x + "px", zIndex: 9999 }}
    >
      {options.map((option, index) => (
        <li
          key={index}
          className="px-4 py-2 hover:bg-zinc-900 text-sm rounded-lg cursor-pointer"
          onClick={option.onClick}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;
